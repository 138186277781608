<template>
    <div class="modal-content animated fadeIn">
        <form @submit.prevent="editInvites()" method="post" class="">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
            <h4 class="modal-title">Edit invite</h4>
        </div>
        <div class="modal-body">
            <h3>{{e_invite.course.name}}</h3>

            <div class="row">
                <div class="col-md-5">
                    <label>Email</label>
                </div>
                <div class="col-md-7">
                    <label>Name (as on ID)</label>
                </div>
            </div>
            <div class="row m-b-xs">
                <div class="col-md-5">
                    <input v-model="e_invite.email" class="form-control input_email" name="email" type="text">
                    <small  v-if="errors.email " class="text-danger">{{errors.email[0]}}</small>
                </div>
                <div class="col-md-7">
                    <input v-model="e_invite.name" class="form-control input_name" name="name" type="text">
                    <small v-if="errors.name " class="text-danger">{{errors.name[0]}}</small>
                </div>
            </div>
            <p>Changing email address will send new invite and will remove any progress made in course.</p>

            <div v-if="e_invite.course.disclaimer" class="row m-t">
                <div class="col-md-12">
                    <p>
                        <label>
                            <input :checked="e_declaration" :model="e_declaration" @click="declarationChange()" value="1" type="checkbox"> {{ e_invite.course.disclaimer }}
                            <br>
                            <small v-if="errors.declaration" class="text-danger">{{errors.declaration[0]}}</small>
                        </label>
                    </p>
                </div>
            </div>
            
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancel</button>
            <button  :disabled="editDisabledSend == 1"  type="submit" class="btn btn-primary">Send</button>
        </div>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errors:{},
            e_invite: {
                email:'',
                name:'',
                all_invites: '',
                not_send_invites: '',
                course: {
                    name:'',
                    disclaimer_required: ''
                }
            },
            editDisabledSend:0,
            rows: 1,
            rowCount: 0,
            e_declaration: 0
        }
    },
    methods: {
        removeRowVal() {
            if(this.rowCount > 0) {
                this.rowCount--;
            }
        },
        addRowVal() {
            if((this.rowCount + this.rows) < this.e_invite.not_send_invites) {
                this.rowCount++;
            }
        },
        addRows() {
            this.rows = this.rows + this.rowCount;
            this.rowCount = 0;
        },
        declarationChange() {
            this.e_declaration = !this.e_declaration;
        },
        editInvites(event) {
            this.editDisabledSend = 1;
            var formData = new FormData();
           // console.log(self.e_invite.course);
           // alert(self.e_invite.course.declaration);
            if(!self.e_invite.course.disclaimer) {
                this.e_declaration = 0;
            }
            
            formData.append("declaration", this.e_declaration);
            formData.append("email", this.e_invite.email);
            formData.append("name", this.e_invite.name);
            formData.append("course_id", this.e_invite.course_id);
            formData.append("owner_id", this.e_invite.owner_id);
            formData.append("invite_id", this.e_invite.id);
            axios
                .post('/invites/update',  formData, { headers: {
                    'Content-Type': 'multipart/form-data'
                }})
                .then((response) => {
                    location.reload();
                }).catch((error) => {
                    this.editDisabledSend = 0;
                    if (422 === error.response.status) {
                        this.errors = error.response.data.errors;
                    } else {
                        this.errorGlobal = error.response.data.message;
                    }
            }); 
        }

    },
    mounted() {
        self = this;
        this.$root.$on('edit-invite', value => {
            self.editDisabledSend = 0;
            self.errors = {};
            self.e_invite = {
                all_invites: '',
                not_send_invites: '',
                course: {
                    name:''
                }
            };
            self.rows = 1,
            self.rowCount = 0;
            self.e_declaration = value.declaration;
            self.e_invite = value;
            console.log(value);
    })
    }
};
</script>