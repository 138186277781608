<template>
    <div class="wrapper wrapper-content">
        <form @submit.prevent="store()" method="post" class="">
        <div class="">
            <div class="ibox">
                <div class="ibox-content">
                    <!-- HEADER -->
                    <div class="page-header">
                            <div class="row">
                                <div class="col-md-12">
                                    <h1>{{course.name}}</h1>
                                </div>
                            </div>
                    </div>
                    <!-- /HEADER -->

            <!-- MODULES -->
            <div class="row">
            <!--LEFT SIDE -->
                <div class="col-md-3">
                    <h3>Edit certificate</h3>
                    <div class="form-group">
                        <label><input v-model="certificate.active" type="checkbox"> Active</label>
                    </div>
                    
                    <div class="form-group">
                        <label>File name*</label>
                        <input :class="{'is-invalid': errors.file_name }"   v-model="certificate.file_name" type="text" class="form-control" >
                        
                        <small v-if="errors.file_name" class="text-danger">{{errors.file_name[0]}}</small>
                    </div>
                    
                    <div class="form-group">
                        <label>Certificate number*</label>
                        <input :class="{'is-invalid': errors.number }" v-model="certificate.number" type="text" class="form-control m-b-xs" >
                        <small v-if="errors.number" class="text-danger">{{errors.number[0]}}</small>
                        <div class="alert alert-info">
                            Use <strong>[NUM]</strong> for <strong>001</strong> number format. You can add additional text. Example: <strong>[NUM]PPCBT</strong> for <strong>001PPCBT</strong>.
                        </div>
                    </div>
                    
                    <div class="form-group">
                        <label>Issued certificates</label>
                        <input type="text" class="form-control m-b-xs" required="" :value="certificate.issued ? certificate.issued : 0" disabled="">
                        <button @click="reset(certificate.id)" class="btn btn-xs btn-danger reset-warning">Reset</button>
                    </div>
                    
                    <div class="form-group">
                        <label>Background</label>
                        <div id="add-file" class="input m-b-sm">
                            <div class="custom-file">
                                <input id="photo" type="file" @change="updateName($event)" class="custom-file-input form-control">
                                <label class="custom-file-label" for="inputGroupFile01">Choose a file</label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <a href="javascript:history.back()" style="" class="btn btn-default">Back</a>
                        <button type="submit" class="btn btn-primary">Save</button>
                        <button @click="preview()" class="btn btn-default">Preview</button>
                    </div>

                </div>
            <!-- /LEFT SIDE -->
            <!-- RIGHT SIDE -->
                <div class="col-md-9">
                    <h3>Content</h3>

                    <div class="alert alert-info">
                        You can use variables in content. Available variables: <strong>[name_as_on_id]</strong>, <strong>[company_name]</strong>, <strong>[date_of_training]</strong>, <strong>[expiry_date]</strong>, <strong>[certificate_number]</strong>, <strong>[user_result]</strong>, <strong>[new_page]</strong>.
                    </div>

                    <div class="row form-row">
                        <div class="col-12 m-b-sm">
                            <div class="sumer-box">
                                <ckeditor :editor="editor" v-model="certificate.content_html" :config="editorConfig"></ckeditor>
                            </div>
                        </div>
                    </div>
                   
                </div>
            <!-- /RIGHT SIDE -->
            </div>
            
            <!-- /MODULES -->
            
                </div>
            </div>
        </div>
        </form>
    </div>
</template>
<script>
import { CKEditor } from '@ckeditor/ckeditor5-vue2';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CustomEditor from '../../../../../resources/js/ckeditor';
//import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
export default {

    props: {
        certificate: '',
        course: ''
    },
    data() {
        return {
            errors: [],
            editor: CustomEditor,
      editorConfig: {
     //   plugins: [Underline], // Dodaj plugin underline
            toolbar: [
                    'heading', '|',
                    'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
                    'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
                    'bulletedList', 'numberedList', 'blockQuote', '|',
                    'link', 'insertImage', 'mediaEmbed', '|',
                    'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties', '|',
                    'undo', 'redo', '|',
                    'alignment', 'indent', 'outdent', '|',
                    'removeFormat', 'highlight', 'specialCharacters', '|',
                    'pageBreak', 'horizontalLine', '|',
                    'findAndReplace', 'codeBlock', 'htmlEmbed'
                ]
            },
            image: {
                toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
            },
            table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
                defaultProperties: {
                    border: '1px solid black',
                    width: '100%',
                    padding: '8px',
                    'border-collapse': 'collapse'
                }
            }
        }
    },
    methods: {
        updateName(e) {
            $(e.target).parent().find('.custom-file-label').html($(e.target)[0].files[0].name);
        },
        reset(id) {
            
            event.preventDefault();
            swal({
                title: 'Are you sure?',
                text: 'It will be reset to 0. You will not be able to recover this data!',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: 'Yes, reset it!',
                cancelButtonText: 'Cancel',
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (value) {
                    var route = '../../../../admin/certificate/reset/'+id;
                     window.location = route;
            });
           // var route = '../../../../admin/certificate/reset/'+id;
           // window.location = route;
        },
        preview(id) {
            event.preventDefault();
            self = this;
            var route = '../../../../admin/certificate/generatepreview';

            var form_data = new FormData();
            var photo = document.querySelector('#photo');

            if(photo.files[0]) {
                form_data.append("bacgkround", photo.files[0]);
            }
            form_data.append('course_id', this.certificate.course_id);
            form_data.append('active', this.certificate.active);
            if(this.certificate.file_name) {
                form_data.append('file_name', this.certificate.file_name);
            }
            if(this.certificate.number) {
                form_data.append('number', this.certificate.number);
            }
            if(this.certificate.content_html) {
                form_data.append('content_html', this.certificate.content_html);
                form_data.append('content_pdf', this.cleanAndPrepareForDOMPDF(this.certificate.content_html));
            }


            axios
                .post(route,   form_data, { headers: {
                    'Content-Type': 'multipart/form-data',
                    'boundary' : Math.random().toString().substr(2)
                }})
                .then((response) => {
                    console.log(response);
                    window.open(
                        response.data, "_blank");

                }).catch((error) => {
                    if (422 === error.response.status) {
                        this.errors = error.response.data.errors;
                    } else {
                        this.errorGlobal = error.response.data.message;
                    }
            }); 
        },
        convertHSLToRGB(hsl) {
                // Funkcja konwertująca HSL na RGB
                let hslMatch = hsl.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/i);
                if (!hslMatch) return hsl;
                let h = parseInt(hslMatch[1]);
                let s = parseInt(hslMatch[2]) / 100;
                let l = parseInt(hslMatch[3]) / 100;

                let c = (1 - Math.abs(2 * l - 1)) * s;
                let x = c * (1 - Math.abs((h / 60) % 2 - 1));
                let m = l - c / 2;
                let r = 0, g = 0, b = 0;

                if (h < 60) { r = c; g = x; b = 0; }
                else if (h < 120) { r = x; g = c; b = 0; }
                else if (h < 180) { r = 0; g = c; b = x; }
                else if (h < 240) { r = 0; g = x; b = c; }
                else if (h < 300) { r = x; g = 0; b = c; }
                else { r = c; g = 0; b = x; }

                r = Math.round((r + m) * 255);
                g = Math.round((g + m) * 255);
                b = Math.round((b + m) * 255);

                return `rgb(${r},${g},${b})`;
        },
        cleanAndPrepareForDOMPDF(htmlContent) {
            htmlContent = htmlContent.replace(/hsl\(\d+,\s*\d+%,\s*\d+%\)/gi, (match) => this.convertHSLToRGB(match));

            htmlContent = htmlContent.replace(/(<[^>]+)\s+style="([^"]*)"\s+style="([^"]*)"/gi, (match, p1, p2, p3) => {
                return `${p1} style="${p2}; ${p3}"`;
            });

            htmlContent = htmlContent.replace(/<table([^>]*)style="([^"]*background-color:[^;]+;[^"]*)"([^>]*)>([\s\S]*?)<\/table>/gi, (match, p1, style, p3, innerHTML) => {
                const updatedInnerHTML = innerHTML.replace(/<td([^>]*)>/gi, `<td$1 style="${style}">`);
                return `<table${p1}style="${style}"${p3}>${updatedInnerHTML}</table>`;
            });

            htmlContent = htmlContent.replace(/<table([^>]*)>/gi, '<table$1 style="width: 100%; border-collapse: collapse; border: 1px solid #000;">');
            htmlContent = htmlContent.replace(/<th([^>]*)>/gi, '<th$1 style="border: 1px solid #000; padding: 8px; text-align: left; background-color: #f0f0f0;">');

            htmlContent = htmlContent.replace(/<td([^>]*)>/gi, (match, p1) => {
                const existingStyle = p1.match(/style="([^"]*)"/);
                let newStyle = 'border: 1px solid #000; padding: 8px; text-align: left;';
                
                if (existingStyle) {
                    newStyle = existingStyle[1] + '; ' + newStyle;  // Łączenie stylów
                }
                
                return `<td${p1.replace(/style="[^"]*"/, '')} style="${newStyle}">`;
            });

            return htmlContent;
        },

        store() {
           
           self = this;
           var route = '../../../../admin/certificate/update';

           var form_data = new FormData();
           var photo = document.querySelector('#photo');

            if(photo.files[0]) {
                form_data.append("bacgkround", photo.files[0]);
            }
           form_data.append('course_id', this.certificate.course_id);
           form_data.append('active', this.certificate.active);
           if(this.certificate.file_name) {
            form_data.append('file_name', this.certificate.file_name);
           }
           if(this.certificate.number) {
            form_data.append('number', this.certificate.number);
           }
           if(this.certificate.content_html) {
                form_data.append('content_html', this.certificate.content_html);
                form_data.append('content_pdf', this.cleanAndPrepareForDOMPDF(this.certificate.content_html));
            }

           axios
                .post(route,   form_data, { headers: {
                    'Content-Type': 'multipart/form-data',
                    'boundary' : Math.random().toString().substr(2)
                }})
                .then((response) => {
                    window.location = '../../../../courses/admin'
                }).catch((error) => {
                    if (422 === error.response.status) {
                        this.errors = error.response.data.errors;
                    } else {
                        this.errorGlobal = error.response.data.message;
                    }
            }); 
             
        },
    } 

}
</script>