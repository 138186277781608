<template>
    <form @submit.prevent="store(event)" method="post" class="">
    <div class="wrapper wrapper-content">
        <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="ibox">
                    <div class="ibox-content">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-md-12">
                                    <h1>Add course</h1>
                                </div>
                            </div>
                        </div>
                        <div class="row section-login m-t">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Course name*</label>
                                    <input :class="{'is-invalid': errors.name }" v-model="form.name" type="text" class="form-control" >
                                    <small v-if="errors.name" class="text-danger">{{errors.name[0]}}</small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Course type*</label>
                                    <select :class="{'is-invalid': errors.type }" v-model="form.type" class="form-control">
                                        <option value="public">Public</option>
                                        <option value="private">Private</option>
                                    </select>
                                    <small v-if="errors.type" class="text-danger">{{errors.type[0]}}</small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Short description*</label>
                                    <textarea :class="{'is-invalid': errors.price }" v-model="form.short_description" class="form-control"></textarea>
                                    <small v-if="errors.short_description" class="text-danger">{{errors.short_description[0]}}</small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Description</label>
                                    <vue-editor v-model="form.description"></vue-editor>
                                    <small v-if="errors.description" class="text-danger">{{errors.description[0]}}</small>
                                </div>
                            </div>
                            
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Basic price*</label>
                                    <input :class="{'is-invalid': errors.price }" v-model="form.price" type="number" Step=".01" class="form-control" >
                                    <small v-if="errors.price" class="text-danger">{{errors.price[0]}}</small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Course image</label>
                                    <div class="custom-file">
                                        <input :class="{'is-invalid': errors.photo }" @change="updateName($event)" type="file" class="custom-file-input" id="photo" name="photo">
                                        <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                        <small>Use 1110x180px image for best result.</small>
                                        <small v-if="errors.photo" class="text-danger">{{errors.photo[0]}}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Disclaimer</label>
                                    <textarea :class="{'is-invalid': errors.disclaimer }" v-model="form.disclaimer" class="form-control"></textarea>
                                    
                                    <small v-if="errors.disclaimer" class="text-danger">{{errors.disclaimer[0]}}</small>
                                    <br  v-if="errors.disclaimer">
                                    <label><input   v-model="form.disclaimer_required"  value="1"  type="checkbox"> Required</label>
                                   
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Expiry date (in days)</label>
                                    <input :class="{'is-invalid': errors.expiry_date }" v-model="form.expiry_date" type="number" Step="1" class="form-control" >
                                    <small v-if="errors.expiry_date" class="text-danger">{{errors.expiry_date[0]}}</small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Renewal interval (in months)</label>
                                    <input :class="{'is-invalid': errors.renewal_interval }" v-model="form.renewal_interval" type="number" Step="1" class="form-control" >
                                    <small v-if="errors.renewal_interval" class="text-danger">{{errors.renewal_interval[0]}}</small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Notify inviting user if course is due for renewal (in days before renewal date)</label>
                                    <input :class="{'is-invalid': errors.notify_due_for_renewal }" v-model="form.notify_due_for_renewal" type="number" Step="1" class="form-control" >
                                    <small v-if="errors.notify_due_for_renewal" class="text-danger">{{errors.notify_due_for_renewal[0]}}</small>
                                </div>
                            </div>



                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Notify invited user if invite is still pending*</label>
                                    
                                    <select :class="{'is-invalid': errors.notify_still_pending }" v-model="form.notify_still_pending" class="form-control">
                                        <option v-for="notify in notifyis" :value="notify.value">{{notify.text}}</option>
                                    </select>
                                    <small v-if="errors.notify_still_pending" class="text-danger">{{errors.notify_still_pending[0]}}</small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Notify invited user if course is still not completed*</label>
                                    <select :class="{'is-invalid': errors.notify_still_not_complited }" v-model="form.notify_still_not_complited" class="form-control">
                                        <option v-for="notify in notifyis" :value="notify.value">{{notify.text}}</option>
                                    </select>
                                    <small v-if="errors.notify_still_not_complited" class="text-danger">{{errors.notify_still_not_complited[0]}}</small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Notify inviting user if invite is due to expire soon (in days before expiry date)</label>
                                    <input :class="{'is-invalid': errors.notify_expire_soon }" v-model="form.notify_expire_soon" type="number" Step="1" class="form-control" >
                                    <small v-if="errors.notify_expire_soon" class="text-danger">{{errors.notify_expire_soon[0]}}</small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <a href="javascript:history.back()" class="btn btn-default">Back</a>
                                    <button type="submit" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</form>
</template>

<script>
export default {
    props: {
        'admin':0,
        'user':''
    },
    data() {
        return {
            errors: [],
            form: {},
            dscilimenrInpt: 1,
            notifyis: [
                {value: 0, text: 'no notifications'},
                {value: 2, text: 'After 2 days of inactivity'},
                {value: 3, text: 'After 3 days of inactivity'},
                {value: 4, text: 'After 4 days of inactivity'},
                {value: 7, text: 'After 7 days of inactivity'},
                {value: 14, text: 'After 14 days of inactivity'},
                {value: 21, text: 'After 21 days of inactivity'},
                {value: 28, text: 'After 28 days of inactivity'}
            ]
        }
    },
    mounted() {
        $(document).ready(function() {
           // $('#summernote').summernote();
        });
    },
    methods: {
        updateName(e) {
            $(e.target).parent().find('.custom-file-label').html($(e.target)[0].files[0].name);
        },
        disclaimerChange(e) {
            this.dscilimenrInpt = !this.dscilimenrInpt;
        },
        store(event) {
            self = this;
            var route = '/courses/admin/storeCourse';


            var formData = new FormData();
            var photo = document.querySelector('#photo');
            if(photo.files[0]) {
                formData.append("photo", photo.files[0]);
            }
            if(this.form.name) {
                formData.append("name", this.form.name);
            }
            if(this.form.type) {
                formData.append("type", this.form.type);
            }
            if(this.form.notify_expire_soon) {
                formData.append("notify_expire_soon", this.form.notify_expire_soon);
            }
            if(this.form.renewal_interval) {
                formData.append("renewal_interval", this.form.renewal_interval);
            }
            if(this.form.notify_due_for_renewal) {
                formData.append("notify_due_for_renewal", this.form.notify_due_for_renewal);
            }
            if(typeof this.form.notify_still_pending !== 'undefined') {
                formData.append("notify_still_pending", this.form.notify_still_pending);
            }
            if(typeof this.form.notify_still_not_complited !== 'undefined') {
                formData.append("notify_still_not_complited", this.form.notify_still_not_complited);
            }
            if(this.form.short_description) {
                formData.append("short_description", this.form.short_description);
            }
            if(this.form.price) {
                formData.append("price", this.form.price.replace(",", "."));
            }
            if(this.form.disclaimer) {
                formData.append("disclaimer", this.form.disclaimer);
            }
            if(this.form.disclaimer_required) {
                formData.append("disclaimer_required", this.form.disclaimer_required == true ? 1 : 0);
            }
            if(this.form.expiry_date) {
                formData.append("expiry_date", this.form.expiry_date);
            }
            if(this.form.description) {
                formData.append("description", this.form.description);
            }

            //name
//type
//short_description

//summernote // description


//price
//photo

            axios
                .post(route,   formData, { headers: {
                    'Content-Type': 'multipart/form-data'
                }})
                .then((response) => {
                    window.location = '../../../courses/admin';
                    
                    
                }).catch((error) => {
                    if (422 === error.response.status) {
                        this.errors = error.response.data.errors;
                    } else {
                        this.errorGlobal = error.response.data.message;
                    }
            });  
        }
    }
}
</script>