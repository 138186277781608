var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._v(" "),(_vm.questionData)?_c('div',{staticClass:"block-questionnaire m-b"},[(_vm.showResult && !_vm.isAnswerCorrect)?_c('div',[_c('div',{staticClass:"alert alert-danger"},[_vm._v("\n                Wrong answer!\n            ")])]):_vm._e(),_vm._v(" "),(_vm.showResult && _vm.isAnswerCorrect)?_c('div',[_c('div',{staticClass:"alert alert-success"},[_vm._v("\n                Correct answer!\n            ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"m-b"},[_c('h3',[_vm._v(_vm._s(_vm.questionData[_vm.currentIndex].question))])]),_vm._v(" "),_c('h3',[_vm._v(_vm._s(_vm.questionData[_vm.currentIndex].title))]),_vm._v(" "),(_vm.questionData[_vm.currentIndex].type === 'radio')?_c('div',_vm._l((_vm.questionData[_vm.currentIndex].answers),function(option,index){return _c('div',{key:index},[_c('label',{class:{
          'answer-selected': _vm.showResult && _vm.isAnswerCorrect && _vm.selectedAnswer === option.answer,
          'wrong-answer': _vm.showResult && !_vm.isAnswerCorrect && _vm.selectedAnswer === option.answer && _vm.isAnswerSelected,
          'answer-not-selected': !_vm.showResult && _vm.selectedAnswer === option.answer && _vm.isAnswerSelected,
          'correct-answer': _vm.showResult && option.correct === 1
        }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedAnswer),expression:"selectedAnswer"}],attrs:{"disabled":_vm.showResult,"type":"radio","name":'question_' + _vm.questionData[_vm.currentIndex].id},domProps:{"value":option.id,"checked":_vm._q(_vm.selectedAnswer,option.id)},on:{"change":function($event){_vm.selectedAnswer=option.id}}}),_vm._v("\n          "+_vm._s(option.answer)+"\n        ")])])}),0):(_vm.questionData[_vm.currentIndex].type === 'check')?_c('div',_vm._l((_vm.questionData[_vm.currentIndex].answers),function(option,index){return _c('div',{key:index},[_c('label',{class:{
          'answer-selected': _vm.showResult && _vm.isAnswerCorrect && _vm.selectedAnswers.includes(option.id),
          'wrong-answer': _vm.showResult && !_vm.isAnswerCorrect && _vm.selectedAnswers.includes(option.id) && _vm.isAnswerSelected,
          'answer-not-selected': !_vm.showResult && _vm.selectedAnswers.includes(option.id) && _vm.isAnswerSelected,
          'correct-answer': _vm.showResult && option.correct === 1
        }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedAnswers),expression:"selectedAnswers"}],attrs:{"disabled":_vm.showResult,"type":"checkbox"},domProps:{"value":option.id,"checked":Array.isArray(_vm.selectedAnswers)?_vm._i(_vm.selectedAnswers,option.id)>-1:(_vm.selectedAnswers)},on:{"change":function($event){var $$a=_vm.selectedAnswers,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedAnswers=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedAnswers=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedAnswers=$$c}}}}),_vm._v("\n          "+_vm._s(option.answer)+"\n        ")])])}),0):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"row m-b"},[(!_vm.showResult)?_c('div',{staticClass:"col-md-12 m-b"},[_c('button',{staticClass:"btn btn-small btn-primary",on:{"click":_vm.checkAnswer}},[_vm._v("Answer "),_c('i',{staticClass:"fas fa-chevron-right",attrs:{"aria-hidden":"true"}})])]):_vm._e(),_vm._v(" "),(_vm.showResult && _vm.showNextBtn)?_c('div',{staticClass:"col-md-12 m-b"},[_c('button',{staticClass:"btn btn-small btn-primary",on:{"click":_vm.getNextQuestion}},[_vm._v("Next "),_c('i',{staticClass:"fas fa-chevron-right",attrs:{"aria-hidden":"true"}})])]):_vm._e()])]):_c('div',[_c('p',[_vm._v("Brak więcej pytań")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-title m-b"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h1',[_vm._v("Questionnaire")])])])])
}]

export { render, staticRenderFns }