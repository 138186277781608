import Vue from 'vue';
import store from 'vuex'
import Select2 from 'v-select2-component';
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use(CKEditor);
require('./bootstrap');
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
//import EventBus from "./services/Events.js";
window.Vue = require('vue').default;
//Vue.prototype.$eventBus = EventBus;

Vue.component('main-cc', require('./components/Main.vue').default);
Vue.component('register-form', require('./components/Auth/Register.vue').default);
Vue.component('edit-user', require('./components/Users/Edit.vue').default);
Vue.component('add-course', require('./components/Courses/Add.vue').default);
Vue.component('edit-course', require('./components/Courses/Edit.vue').default);
Vue.component('edit-account', require('./components/Users/Account.vue').default);
Vue.component('add-invite', require('./components/Admin/Invites/AddInvite.vue').default);
Vue.component('edit-invite', require('./components/Admin/Invites/EditInvite.vue').default);
Vue.component('slide-boxes', require('./components/Slides/SlideBoxes.vue').default);
Vue.component('slide-questionmaire-boxes', require('./components/Slides/QuestionareBoxes.vue').default);
Vue.component('slide-questionere', require('./components/Courses/SlideQuestionare.vue').default);
Vue.component('slide-test-boxes', require('./components/Slides/TestBoxes.vue').default);
Vue.component('certicicate-edit', require('./components/Admin/Certificate/Edit.vue').default);

//SlideQuestionare
//Vue.component('summernote', require('./Summernote.js').default);

const app = new Vue({
    el: '#app',
    store: store,
});
Object.defineProperties(window, {
    addInvite: {
        set: function(val) {
            app.$root.$emit('add-invite', val);
        }
    },
    editInvite: {
        set: function(val) {
            app.$root.$emit('edit-invite', val);
        }
    },
    defaultUpdateAction: {
        set: function(val) {
            app.$root.$emit('default-update-action', val);
        }
    },
});