<template>
<div class="col-md-9" >
    <form @submit.prevent="store()" method="post" class="">
       
  
    <div >
        <div class="row">
        <div class="col-md-6">
            <h3>Edit module</h3>
        </div>
        <div class="col-md-6">

            <a type="button" class="btn btn-danger btn-sm m-b sweet-alert-delete float-right sweet-alert-delete delete-warning " :data-id=slide.module.id
                data-route="../../../../admin/slides/destroyTestModule" title="Delete">Delete module</a>

        </div>
    </div>
        <div class="form-group">
            <input type="hidden" name="slide_id" value="">
            <label>Module name*</label>
            <input v-model="slide.module.name" type="text" name="name" class="form-control" >
            <small v-if="errors && errors['slide.module.name']" class="text-danger">{{errors['slide.module.name'][0]}}</small>
        </div>
        <div class="form-group">
            <label>Module types*</label>
            <input disabled type="text" name="name" class="form-control" value="Test">
        </div>
        <div class="form-group">
        <label>Description</label>
        <div class="sumer-box">
            <vue-editor v-model="slide.module.description"></vue-editor>
        </div>
       
        </div>

        <div class="form-group">
            <label>Maximum attempts (enter "0" for unlimited)*</label>
            <input type="number" v-model="slide.module.maximum_attempts" class="form-control m-b-sm" value="0">
            <small v-if="errors && errors['slide.module.maximum_attempts']" class="text-danger">{{errors['slide.module.maximum_attempts'][0]}}</small>
        </div>
        <div class="form-group">
            <label>Pass mark %*</label>
            <input type="number" v-model="slide.module.pass_mark"  class="form-control">
            <small v-if="errors && errors['slide.module.maximum_attempts']" class="text-danger">{{errors['slide.module.maximum_attempts'][0]}}</small>
        </div>
   
        <h3>Test</h3>
        <p><label><input type="checkbox" v-model="slide.module.randomise_questions"> Randomise questions</label><br>
        <label><input type="checkbox" v-model="slide.module.randomise_answears"> Randomise answers</label></p>
    <div v-for="(box, index) in boxes" class="knowledge-editor">
        <div class="knowledge-editor-title">
            {{type_names[box.type]}}
            <a hef="#" @click="remove(index)"><i class="fa fa-trash float-right m-l-xs "></i></a>
            <a hef="#" v-if="index != 0" @click="move(index,index-1)"><i class="fa fa-arrow-up m-l-xs float-right"></i></a>
            <a hef="#" @click="move(index,index+1)"><i class="fa fa-arrow-down m-l-xs float-right"></i></a>
        </div>
        <div class="knowledge-editor-content">
            <div v-if="box.type == 'check'">
                
                <p style="font-size: 80%;" v-if="box.errors && box.errors['question']" class="text-danger"> {{ box.errors['question'] }}</p>
                <p style="font-size: 80%;" v-if="box.errors && box.errors['answers'] && box.errors['answers'] == 'At least one answer is required'" class="text-danger"> {{ box.errors['answers'] }}</p>
                <h4>{{box.question}}</h4>
                <div v-for="(answer, key) in box.answers">
                    <label v-if="answer.correct"><input type="checkbox" checked="" disabled=""> {{answer.answer}}</label>
                    <label v-else><input type="checkbox"  disabled=""> {{answer.answer}}</label>
                    <small v-if="errors[`boxes.${index}.answers.${key}.answer`] " class="text-danger" :class="'danger'+index">{{errors[`boxes.${index}.answers.${key}.answer`][0]}}</small>
                   
                </div>
                
                <button class="btn btn-primary btn-xs" data-toggle="modal" @click="editQuestion(index, box)">Edit</button>
                
            </div>
            <div  v-if="box.type == 'radio'">
                <p style="font-size: 80%;" v-if="box.errors && box.errors['question']" class="text-danger"> {{ box.errors['question'] }}</p>
                <p style="font-size: 80%;" v-if="box.errors && box.errors['answers'] && box.errors['answers'] == 'At least one answer is required'" class="text-danger"> {{ box.errors['answers'] }}</p>
                <h4>{{box.question}}</h4>
                <div v-for="(answer, key) in box.answers">
                    <label v-if="answer.correct"><input type="radio" checked="" disabled=""> {{answer.answer}}</label>
                    <label v-else><input type="radio" disabled=""> {{answer.answer}}</label>
                    <small v-if="errors[`boxes.${index}.answers.${key}.answer`] " class="text-danger" :class="'danger'+index">{{errors[`boxes.${index}.answers.${key}.answer`][0]}}</small>
                </div>
                
                <button class="btn btn-primary btn-xs" data-toggle="modal" @click="editQuestion(index, box)">Edit</button>
                
            </div>
        </div>
        <br>
    </div>
    <br>
    <div class="row">
        <div class="hr-class"></div>
        <div class="col">
            <label>Add content block*</label>
            <select v-model="current_type_add" class="form-control current_type_class">
                <option v-for="(value, name, index) in  type_names" :value="name">{{value}}</option>
            </select>
        </div>
        <div class="col-auto">
            <a @click="addRow()" class="btn btn-primary" style="margin-top:20px; color:white;">Add</a>
        </div>
       
        
        
    </div>
    <div class="row">
        <div class="col-auto">
            <button type="submit" class="btn btn-primary" style="margin-top:20px;">Save</button>
            </div>
        </div>
    </div>
    </form>

    <!-- MODAL-->
    <div class="modal inmodal" id="editSingleChoiceModal" tabindex="-1" role="dialog" aria-modal="true" style="padding-right: 15px; display: none;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                    <h4 class="modal-title">Edit question</h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <label>Question*</label>
                            <textarea v-model="current_edit_model.question" class="form-control"></textarea>
                        </div>
                    </div>
                    <div style="padding:0px  15px;" class="row"> 
                        <label>MP3 file</label>
                        <div class="col-md-12">
                            <input accept=".mp3" type="file" @change="updateName($event, current_edit_model)" class="custom-file-input form-control">
                            <label class="custom-file-label" for="inputGroupFile01">{{this.current_edit_model.full_mp3}}</label>
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label>Answers* (use tick boxes to select correct answer/s)</label>
                        </div>
                    </div>

                    <div v-for="(answer, index) in current_edit_model.answers" v-if="current_edit_model.type == 'radio'" class="row m-b-xs">
                        <div class="col modal-answear  flex-slides-box align-self-center ">
                            <input type="radio" name="ans1" 
                                @click="updateRadioAnswer(index)" 
                                :checked="answer.correct" 
                            value=1>
                        </div>
                        <div class="col-11">
                            <input class="form-control" type="text" v-model="answer.answer">
                            <label>MP3 file</label>
                            <div class="col-md-12">
                                <input accept=".mp3" type="file" @change="updateQuestionMp3($event, answer)" class="custom-file-input form-control">
                                <label class="custom-file-label" for="inputGroupFile01">{{answer.full_mp3}}</label>
                            </div>
                        </div>
                    </div>
                    
                    <div v-for="answer in current_edit_model.answers" v-if="current_edit_model.type == 'check'" class="row m-b-xs">
                        <div class="col modal-answear  flex-slides-box align-self-center ">
                            <input type="checkbox"  v-model="answer.correct" value="1">
                        </div>
                        <div class="col-11">
                            <input class="form-control" type="text" v-model="answer.answer">
                            <label>MP3 file</label>
                            <div class="col-md-12">
                                <input accept=".mp3" type="file" @change="updateQuestionMp3($event, answer)" class="custom-file-input form-control">
                                <label class="custom-file-label" for="inputGroupFile01">{{answer.full_mp3}}</label>
                            </div>
                        </div>
                    </div>
                    <small v-if="one_correct_fail" class="text-danger"> At least one answer is need to be correct</small>
                
                    <div class="row">
                        <div class="col-md-7">
                            <a href="#" @click="removeAnswer()" type="button" class="btn btn-small btn-danger">Remove answer</a>
                            <a href="#" @click="addAnswer()" type="button" class="btn btn-small btn-primary">Add answer</a>
                        </div>
                    </div>	
                    <div class="row">
                        <div class="col-md-12">
                            <label>Wrong answer feedback</label>
                            <textarea v-model="current_edit_model.feedback" class="form-control"></textarea>
                        </div>
                    </div>	
                
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-white" data-dismiss="modal">Cancel</button>
                    <a href="#" @click="saveQuestion()" type="button" class="btn btn-primary">Save</a>
                </div>

            </div>
        </div>
    </div>

</div>

 </template>

 <script>
 export default {
    props: {

        'return' : '',
        'contents' : {},
        'slide' : {}
    },
    data() {
        return {
            one_correct_fail: false,
            selectedSlideTypeOption: '',
            slideOptions: ['Content', 'Questionnaire'],
            errors: [],
            current_type_add : 'radio',
            current_edit_index: 0,
            current_edit_model: {
                question: 'nope',
                answers: [],
                type: 'check',
                mp3: '',
                full_mp3: ''
            },
            blank_types: {
                check: {
                    type: 'check',
                    answers:[],
                    mp3: '',
                    full_mp3: ''
                },
                radio: {
                    type: 'radio',
                    answers:[],
                    mp3: '',
                    full_mp3: ''
                },
            },
            blank_answer: {
                answer: '',
                correct: 0,
                
            },
            type_names: {
                radio : 'Single choice',
                check : 'Multiple choice',
            },
            boxes: {}
        }
    },
    methods: {
        updateQuestionMp3(e, anser) {
            $(e.target).parent().find('.custom-file-label').html($(e.target)[0].files[0].name);
            anser.mp3 = $(e.target)[0].files[0];
            anser.full_mp3 = $(e.target)[0].files[0].name;
            anser.updated = 1;
        },
        updateName(e, question) {
           // $(e.target).parent().find('.custom-file-label').html($(e.target)[0].files[0].name);
            this.current_edit_model.full_mp3 = $(e.target)[0].files[0].name;
            question.mp3 = $(e.target)[0].files[0];
            question.full_mp3 = $(e.target)[0].files[0].name;
            question.updated = 1;
        },
        editQuestion(index, question) {
            this.one_correct_fail = false;
            this.current_edit_index = index;
        //    this.current_edit_model.full_mp3 = '';
            this.current_edit_model.answers.forEach(function(t) {
                t.full_mp3 = '';
            })
            this.current_edit_model = question;// JSON.parse(JSON.stringify(question));
            event.preventDefault();
            $('#editSingleChoiceModal').modal('show');
        },
        updateRadioAnswer(index) {
            console.log(this.current_edit_model.answers.length);
            for(let i=0; i < this.current_edit_model.answers.length; i++) {
                if(i==index) {
                    this.current_edit_model.answers[i].correct = 1;
                } else {
                    this.current_edit_model.answers[i].correct = 0;
                }
            }
        },
        saveQuestion() {
            event.preventDefault();
            var updated = this.current_edit_model;//JSON.parse(JSON.stringify(this.current_edit_model));
            var correct = false;
            updated.answers.forEach(function(v) {
                if(v.correct == 1) {
                    correct= true;
                }
            });
            if(correct) {
                this.boxes[this.current_edit_index] = updated;
                //this.boxes = JSON.parse(JSON.stringify(this.boxes));
                $('#editSingleChoiceModal').modal('hide'); 
            } else {
             //   console.log(this.current_edit_model);
                this.one_correct_fail = true;
               // console.log(this.current_edit_model);
            }
            
        },
        addAnswer() {
           
            var blank = JSON.parse(JSON.stringify(this.blank_answer))
            if(this.current_edit_model.answers.length == 0) {
                blank.correct = 1;
            }
            this.current_edit_model.answers.push(blank);
        },
        removeAnswer() {
            if(this.current_edit_model.type == 'radio') {
                if(this.current_edit_model.answers[this.current_edit_model.answers.length -1].correct) {

                    if(this.current_edit_model.answers.length > 1) {
 
                        this.current_edit_model.answers[0].correct = 1;
                    }
                }
            }
           this.current_edit_model.answers.splice(-1);
        },
        remove(index) {
            var self = this;
            swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this data!',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function () {
                    if(self.boxes[index].id != 'undefined') {
                        var route = '../../../../admin/slides/destroyQuestion';
                        axios
                            .post(route,  {'id' : self.boxes[index].id })
                            .then((response) => {
                                self.boxes.splice(index, 1);
                            })
                    } else {
                        self.boxes.splice(index, 1);
                    }
            });
            
        },
        move(from, to) {
            this.boxes.move(from, to);
        },

        addRow() {
            var box = JSON.parse(JSON.stringify(this.blank_types[this.current_type_add]));
            this.boxes.push(box);
        },
        store() {
           self = this;
           var route = '../../../../admin/slides/updateTestQuestionare';
           var data = {slide : this.slide, boxes: this.boxes};
         

           var form_data = new FormData();

      //  form_data.append('slide', this.slide);
        form_data.append('slide[module][id]', this.slide.module.id);
        form_data.append('slide[module][name]', this.slide.module.name);
        form_data.append('slide[module][description]', this.slide.module.description);
        form_data.append('slide[module][randomise_questions]', this.slide.module.randomise_questions);
        form_data.append('slide[module][randomise_answears]', this.slide.module.randomise_answears);
        form_data.append('slide[module][pass_mark]', this.slide.module.pass_mark);
        form_data.append('slide[module][maximum_attempts]', this.slide.module.maximum_attempts);
           
        let i = 0;
        console.log(self.boxes);
        for (var k in self.boxes ) {
            for (var l in self.boxes[k] ) {
                if(l != 'errors') {
                    form_data.append('boxes['+i+']'+'['+l+']', self.boxes[k][l]);
                    if(Array.isArray(self.boxes[k][l])) {
                        for( var a in self.boxes[k][l]) {
                            for( var r in self.boxes[k][l][a]) {
                            if(r == 'mp3') {
                                form_data.append('boxes['+i+']'+'[answers]['+a+']['+r+']', self.boxes[k][l][a][r]);
                            } else {
                                form_data.append('boxes['+i+']'+'[answers]['+a+']['+r+']', self.boxes[k][l][a][r]);
                            }
                            }
                        }
                    }
                }
            }
            i++;
}
            axios
                .post(route,   form_data, { headers: {
                    'Content-Type': 'multipart/form-data',
                    'boundary' : Math.random().toString().substr(2)
                }})
                .then((response) => {
                    window.location.reload();
                  //  window.location = self.return;
                }).catch((error) => {
                    if (422 === error.response.status) {
                        this.errors = error.response.data.errors;
                        self = this;
                        console.log(this.errors);
                        for (var k in this.errors ) {
                            var temp = k.split(".");
                            if(temp[0] == 'boxes') {
                                if(!Array.isArray(self.boxes[temp[1]]['errors'])) {
                                    self.boxes[temp[1]]['errors'] = [];
                                }
                                //answers
                                if(this.errors[k][0])
                                self.boxes[temp[1]]['errors'][temp[2]] = this.errors[k][0];
                            }
                        }
                        console.log(self.boxes);
                    } else {
                        this.errorGlobal = error.response.data.message;
                    }
            });  


           /*axios
                .post(route,   data)
                .then((response) => {
                    window.location.reload();
                    //window.location = self.return;
                }).catch((error) => {
                    if (422 === error.response.status) {
                        this.errors = error.response.data.errors;
                        self = this;
                        console.log(this.errors);
                        for (var k in this.errors ) {
                            var temp = k.split(".");
                            if(temp[0] == 'boxes') {
                                if(!Array.isArray(self.boxes[temp[1]]['errors'])) {
                                    self.boxes[temp[1]]['errors'] = [];
                                }
                                //answers
                                if(this.errors[k][0])
                                self.boxes[temp[1]]['errors'][temp[2]] = this.errors[k][0];
                            }
                        }
                        console.log(self.boxes);
                    } else {
                        this.errorGlobal = error.response.data.message;
                    }
            });  
            */
        },
       
    },
    mounted() {
        Array.prototype.move = function(from, to) {
            this.splice(to, 0, this.splice(from, 1)[0]);
            return this;
        };
        this.selectedSlideTypeOption = this.slide.type;
        this.boxes = this.contents;
        console.log(this.boxes);
    },

    
 };
 </script>
 