<template>
    <div class="modal-content animated fadeIn">
        <form @submit.prevent="storeInvites()" method="post" class="">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
            <h4 class="modal-title">Invite others</h4>
        </div>
        <div class="modal-body">
            <h3>{{invite.course.name}}</h3>
            <p>Invites remaining: <strong>{{invite.not_send_invites}}/{{ invite.all_invites }}</strong></p>
    
            <div class="row">
                <div class="col-md-5">
                    <label>Email</label>
                </div>
                <div class="col-md-7">
                    <label>Name (as on ID)</label>
                    
                </div>
            </div>
        
            <div v-for="(row, index) in rows" class="row m-b-xs">
                <div class="col-md-5">
                    <input class="form-control input_email" v-model="emailinp[index]"  name="email" type="text">
                    <small v-if="errors[`email.${index}`] " class="text-danger">{{errors[`email.${index}`][0]}}</small>
                </div>
                <div class="col-md-7">
                    <input class="form-control input_name" v-model="nameinp[index]" name="name" type="text">
                    <small v-if="errors[`name.${index}`] " class="text-danger">{{errors[`name.${index}`][0]}}</small>
                </div>
            </div>
            <small v-if="to_much_rows" class="text-danger">The number of lines in the file exceeds the available number of invitations</small>
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected"><span class="input-group-btn input-group-prepend">
                        <button @click="removeRowVal" class="btn btn-white bootstrap-touchspin-down" type="button">-</button>
                            </span>
                                <input class="touchspin1 form-control" type="number" :model="rowCount"  :value="rowCount" name="demo1">
                            <span class="input-group-btn input-group-append">
                        <button @click="addRowVal" class="btn btn-white bootstrap-touchspin-up" type="button">+</button>
                        </span></div>
                </div>
                <div class="col-md-7 m-t-mobile">
                    <button @click="addRows()" type="button" class="btn btn-small btn-primary">Add rows</button>
                    <button @click="importCSV()" type="button" class="btn btn-small btn-primary">Import CSV</button>
                    <input id="csv-input" style="display:none" type="file" @change="csvFileChange">
                </div>
            </div>
            <div v-if="invite.course.disclaimer" class="row m-t">
                <div class="col-md-12">
                    <p>
                        <label>
                            <input  :model="declaration" @click="declarationChange()" value="1" type="checkbox"> {{ invite.course.disclaimer }}
                            <br>
                            <small v-if="errors.declaration" class="text-danger">{{errors.declaration[0]}}</small>
                        </label>
                    </p>
                </div>
            </div>
            
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancel</button>
            <button :disabled="disabledSend == 1" type="submit" class="btn btn-primary">Send</button>
        </div>
        </form>
    </div>
</template>

<script>
export default {
    props: {
        user_id: false
    },
    data() {
        return {
            errors:{},
            invite: {
                all_invites: 6,
                not_send_invites: '',
                course: {
                    name:'',
                    disclaimer_required: ''
                }
            },
            to_much_rows: 0,
            emailinp: [''],
            nameinp: [''],
            disabledSend:0,
            rows: 1,
            rowCount: 0,
            declaration: 0
        }
    },
    methods: {
        importCSV() {
            $('#csv-input').click();
        },
        csvFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            var csvFormData = new FormData();
            csvFormData.append("file", files[0]);
            self = this;
            axios
                .post('/invites/imortCSV',  csvFormData, { headers: {
                    'Content-Type': 'application/json'
                }})
                .then((response) => {
                    console.log(response);
                    
                    if(response.data.fail) {
                        toastr.error(response.data.message);
                        return;
                    }
                    var data = response.data;
                    for (const key in data) {
                        self.uploadRow(data[key]);
                    };
                    toastr.success('Data imported correctly');
                }).catch((error) => {
                    console.log(error);
                  
            }); 
         },
        uploadRow(data) {
            var loop = this.rows -1;
            if(this.emailinp[this.rows -1] != '' ||  this.nameinp[this.rows -1] != '') {
                loop++;
            }
            this.uploadByLoop(data, loop);
        },
        uploadByLoop(data, loop) {
            if(loop == this.invite.not_send_invites) {
                this.to_much_rows = 1;
                return;
            }
            if(this.rows == loop) {
                
                this.rows ++;
            }
            this.emailinp[this.rows -1] = data[0];
            this.nameinp[this.rows -1] = data[1];
        },
        removeRowVal() {
            if(this.rowCount > 0) {
                this.rowCount--;
            }
        },
        addRowVal() {
            if((this.rowCount + this.rows) < this.invite.not_send_invites) {
                this.rowCount++;
            }
        },
        addRows() {
            this.rows = this.rows + this.rowCount;
            this.rowCount = 0;
        },
        declarationChange() {
            this.declaration = !this.declaration;
        },
        storeInvites(event) {
            this.disabledSend = 1;
            var formData = new FormData();

            formData.append("declaration", this.declaration);
            if(this.user_id != 'false') {
                formData.append("user_id", this.user_id);
            }
            formData.append("course_id", this.invite.course_id);
            formData.append("owner_id", this.invite.owner_id);
   
            $('.input_email').each(function() {
                formData.append("email[]", $(this).val());
            })
            $('.input_name').each(function() {
                formData.append("name[]", $(this).val());
            })
            $()
            axios
                .post('/invites/add',  formData, { headers: {
                    'Content-Type': 'multipart/form-data'
                }})
                .then((response) => {
                    location.reload();
                }).catch((error) => {
                    this.disabledSend = 0;
                    if (422 === error.response.status) {
                        this.errors = error.response.data.errors;
                    } else {
                        this.errorGlobal = error.response.data.message;
                    }
            }); 
        }

    },
    mounted() {
        var self_x = this;
            this.$root.$on('add-invite', value => {
                console.log(value);
                self.disabledSend = 0;
                self_x.errors = {},
                self_x.invite = {
                    all_invites: '',
                    not_send_invites: '',
                    course: {
                        name:''
                    }
                };
                self.emailinp = [''];
                self.nameinp = [''];
                self.to_much_rows = 0;
                self_x.rows = 1,
                self_x.rowCount = 0;
                self_x.declaration = 0;
                self_x.invite = value;
            });
        }
};
</script>