<template>
<div>
    <form @submit.prevent="store()" method="post" class="">
       
    <div class="row">
    <div class="col-md-3">
        <h3>Edit Slide</h3>
        <div class="form-group">
            <input type="hidden" name="slide_id" value="">
            <label>Slide name*</label>
            <input v-model="slide.name" type="text" name="name" class="form-control" >
            <small v-if="errors && errors['slide.name']" class="text-danger">{{errors['slide.name'][0]}}</small>
        </div>
        <div class="form-group">
            <label>Slide type*</label>
            <select @change="changeSlideType()" v-model="selectedSlideTypeOption" name="type" class="form-control">
                <option :selected="selectedSlideTypeOption == option" v-for="option in slideOptions" :value="option">{{ option}}</option>
            </select>
        </div>
        <div class="form-group">
            <label>MP3 file</label>
            <div id="add-file" class="input m-b-sm">
                <div class="custom-file">
                    <input id="mp3" type="file" @change="updateName($event)" class="custom-file-input form-control">
                    <label class="custom-file-label" for="inputGroupFile01">{{slide.full_mp3}}</label>
                </div>
            </div>
            <small v-if="errors && errors['slide.mp3']" class="text-danger">{{errors['slide.mp3'][0]}}</small>
        </div>
        <div class="row">
            <a href="javascript:history.back()" style="margin-top:20px; margin-left:15px; margin-right:5px;" class="btn btn-default">Back</a>
            <button type="submit" class="btn btn-primary" style="margin-top:20px;">Save</button>
        </div>
    </div>

    <div class="col-md-9">
    <div v-for="(box, index) in boxes" class="knowledge-editor">
        <div class="knowledge-editor-title">
            {{type_names[box.type]}}
            <a hef="#" @click="remove(index)"><i class="fa fa-trash float-right m-l-xs "></i></a>
            <a hef="#" v-if="index != 0" @click="move(index,index-1)"><i class="fa fa-arrow-up m-l-xs float-right"></i></a>
            <a hef="#" @click="move(index,index+1)"><i class="fa fa-arrow-down m-l-xs float-right"></i></a>
        </div>
        <div class="knowledge-editor-content">
            <div v-if="box.type == 'title_block'">
                <label>Title*</label>
                <input v-model="box.title" type="text"  class="form-control m-b-xs" >
                <small v-if="box.errors && box.errors['title']" class="text-danger"> {{ box.errors['title'] }}</small>

            </div>
            <div v-if="box.type == 'text_block'">
                <label>Content*</label>
                <div class="sumer-box">
                    <vue-editor v-model="box.content"></vue-editor>
                </div>
                <small v-if="box.errors && box.errors['content']" class="text-danger"> {{ box.errors['content'] }}</small>
            </div>
            <div v-if="box.type == 'image_and_text_block'">
                <label><input
                        type="radio"
                        value="left"
                        v-model="box.image_pos"> Image left, text right</label>
                <br>
                <label><input 
                        type="radio"
                        value="right"
                        v-model="box.image_pos" > Image right, text left</label>
                <br>
                <small v-if="box.errors && box.errors['image_pos']" class="text-danger"> {{ box.errors['image_pos'] }}</small>
                <br>
                <label>Image file* </label>
                <div class="input m-b-sm">
                    <div class="custom-file">
                        <input v-if="!box.progress" type="file" @change="updateFile($event, index)" class="custom-file-input form-control">
                        <label v-if="!box.progress" class="custom-file-label" for="inputGroupFile01">{{box.file}}</label>
                        <div v-if="box.progress" style=" height: 25px" class="progress mt-3">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"  :style="{ width: box.progress_percent + '%' }" style=" height: 100%">{{box.progress_percent}}%</div>
                        </div>
                    </div>
                    <small v-if="box.errors && box.errors['file']" class="text-danger"> {{ box.errors['file'] }}</small>
                </div>
                <label>Content*</label>
                <vue-editor v-model="box.content"></vue-editor>
                <small v-if="box.errors && box.errors['content']" class="text-danger"> {{ box.errors['content'] }}</small>
            </div>
            <div v-if="box.type == 'full_width_image_block'">
                <label>Image file*</label>
                <div class="input m-b-sm">
                    <div class="custom-file">
                        <input v-if="!box.progress" type="file" @change="updateFile($event, index)" class="custom-file-input form-control">
                        <label v-if="!box.progress" class="custom-file-label" for="inputGroupFile01">{{box.file}}</label>
                        <div v-if="box.progress" style=" height: 25px" class="progress mt-3">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"  :style="{ width: box.progress_percent + '%' }" style=" height: 100%">{{box.progress_percent}}%</div>
                        </div>
                    </div>
                    <small v-if="box.errors && box.errors['file']" class="text-danger"> {{ box.errors['file'] }}</small>
                </div>

            </div>
            <div v-if="box.type == 'video_block'">
                <label>Video file*</label>
                <div class="input m-b-sm">
                    <div class="custom-file">
                        <input v-if="!box.progress" type="file" @change="updateFile($event, index)" class="custom-file-input form-control">
                        <label v-if="!box.progress" class="custom-file-label" for="inputGroupFile01">{{box.file}}</label>
                        <div v-if="box.progress" style=" height: 25px" class="progress mt-3">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"  :style="{ width: box.progress_percent + '%' }" style=" height: 100%">{{box.progress_percent}}%</div>
                        </div>
                    </div>
                    <small v-if="box.errors && box.errors['file']" class="text-danger"> {{ box.errors['file'] }}</small>
                </div>
            </div>
        </div>
        <br>
    </div>
    <br>
    <div class="row">
        <div class="hr-class"></div>
        <div class="col">
            <label>Add content block*</label>
            <select v-model="current_type_add" class="form-control current_type_class">
                <option v-for="(value, name, index) in  type_names" :value="name">{{value}}</option>
            </select>
        </div>
        <div class="col-auto">
            <a @click="addRow()" class="btn btn-primary" style="margin-top:20px; color:white;">Add</a>
        </div>
        
    </div>
    </div>
</div>
    </form>
    


</div>

 </template>

 <script>
import { VueEditor } from "vue2-editor";
 export default {
    props: {
        'uploadroute' : '',
        'token' : '',
        'return' : '',
        'contents' : {},
        'slide' : {}
    },
    data() {
        return {
            content1: 'rr',
            errors: [],
            selectedSlideTypeOption: 'Content',
            slideOptions: ['Content', 'Questionnaire'],
            current_type_add : 'title_block',
            blank_types: {
                title_block: {
                    type: 'title_block',
                    title: '',
                },
                text_block: {
                    type: 'text_block',
                    content: ''
                },
                image_and_text_block: {
                    type: 'image_and_text_block',
                    image_pos: '',
                    content: '',
                    file: 'Choose a file',
                    file_path: false,
                    progress: false,
                    progress_percent: 0
                },
                full_width_image_block: {
                    type: 'full_width_image_block',
                    file: 'Choose a file',
                    file_path: false,
                    progress: false,
                    progress_percent: 0
                },
                video_block: {
                    type: 'video_block',
                    file: 'Choose a file',
                    file_path: false,
                    progress: false,
                    progress_percent: 0
                },
            },
            type_names: {
                title_block : 'Title block',
                text_block : 'Text block (WYSIWYG)',
                image_and_text_block : 'Image and text block',
                full_width_image_block : 'Full width image block',
                video_block: 'Video block'
            },
            boxes: {}
        }
    },
    methods: {
        changeSlideType() {
            self = this;
         
            self.selectedSlideTypeOption = 'Content'

            swal({
                title: 'Are you sure?',
                text: 'Changing slide type will remove all added blocks',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (value) {
                    if(value == false) {
                        if(self.slide.type == 'Questionnaire') {
                            self.selectedSlideTypeOption = 'Content'
                        } else {
                            self.selectedSlideTypeOption = 'Content'
                        }
                    } else {
                        var route = '../../../../admin/slides/changeSlideType';
                    axios
                        .post(route,  {'id' : self.slide.id })
                        .then((response) => {
                            window.location.reload();
                        }) 
                    }
                    
            });
            
        },
        updateSn(index, current) {
            console.log(this);
            console.log(index);
        },
        remove(index) {
            var self = this;
            swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this data!',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function () {
                    if(self.boxes[index].id != 'undefined') {
                        var route = '../../../../admin/slides/destroyBox';
                        axios
                            .post(route,  {'id' : self.boxes[index].id })
                            .then((response) => {
                                $('.summernote').each(function() {
                                    self.boxes[$(this).data('index')].content = '';
                                    self.boxes[$(this).data('index')].content = $(this).summernote('code');
                                    $(this).summernote('destroy');
                                });
                                self.boxes.splice(index, 1);
                                setTimeout(function() {
                                        $('.summernote').each(function() {
                                        $(this).summernote();
                                    })
                                }, 100);
                            })
                    } else {
                        $('.summernote').each(function() {
                            self.boxes[$(this).data('index')].content = '';
                            self.boxes[$(this).data('index')].content = $(this).summernote('code');
                            $(this).summernote('destroy');
                        });
                        self.boxes.splice(index, 1);
                            setTimeout(function() {
                            $('.summernote').each(function() {
                            $(this).summernote();
                        })
            }, 100);
                    }
            });
            
        },
        move(from, to) {
           self = this;
            $('.summernote').each(function() {
                self.boxes[$(this).data('index')].content = '';
                self.boxes[$(this).data('index')].content = $(this).summernote('code');
                $(this).summernote('destroy');
            });
            this.boxes.move(from, to);
                setTimeout(function() {
                    $('.summernote').each(function() {
                    $(this).summernote();
                })
                }, 100);
            
        },
        updateName(e) {
            $(e.target).parent().find('.custom-file-label').html($(e.target)[0].files[0].name);
        },
        updateFile(e, index) {
            self = this;
            self.boxes[index].progress = true;

            let browseFile = $(e.target);
            let resumable = new Resumable({
                target: self.uploadroute,
                query: {_token: self.token},
                fileType: ['png', 'jpg', 'jpeg', 'mp4'],
              //  chunkSize: 2 * 1024 * 1024, // default is 1*1024*1024, this should be less than your maximum limit in php.ini
                headers: {
                    'Accept': 'application/json'
                },
                testChunks: false,
                throttleProgressCallbacks: 1,
            });

            resumable.assignBrowse(browseFile[0]);

           
          //  showProgress();
            resumable.upload();
            console.log(resumable);
            resumable.upload();
            resumable.on('fileAdded', function (file) { // trigger when file picked
            //    showProgress();
                resumable.upload() // to actually start uploading.
            }); 

            resumable.on('fileProgress', function (file) { // trigger when file progress update
                console.log(Math.floor(file.progress() * 100));
                self.boxes[index].progress_percent = Math.floor(file.progress() * 100);

            });

            resumable.on('fileSuccess', function (file, response) { // trigger when file upload complete
                response = JSON.parse(response);
                self.boxes[index].progress = false;

                self.boxes[index].file_path = response.path;
                self.boxes[index].file = response.name;


            });

            resumable.on('fileError', function (file, response) { // trigger when there is any error
                alert('file uploading error.')
            });

            let progress = $('.progress');

            function showProgress() {
                progress.find('.progress-bar').css('width', '0%');
                progress.find('.progress-bar').html('0%');
                progress.find('.progress-bar').removeClass('bg-success');
                progress.show();
            }

            function updateProgress(value) {
                progress.find('.progress-bar').css('width', `${value}%`)
                progress.find('.progress-bar').html(`${value}%`)

                if (value === 100) {
                    progress.find('.progress-bar').addClass('bg-success');
                }
            }

            function hideProgress() {
                progress.hide();
            }
            resumable.addFile($(e.target)[0].files[0])

        },
        addRow() {
            self = this;
            var box = JSON.parse(JSON.stringify(this.blank_types[this.current_type_add]));
            this.boxes.push(box);
        },
        store() {
           self = this;
           var route = '../../../../admin/slides/update';
           var data = {slide : this.slide, boxes: this.boxes};
           console.log(data);
           for (var k in self.boxes ) {
                self.boxes[k]['errors'] = [];
           }

           var form_data = new FormData();


            var mp3 = document.querySelector('#mp3');
            if(mp3.files[0]) {
                form_data.append("slide[mp3]", mp3.files[0]);
            }
           form_data.append('slide[id]', this.slide.id);
           form_data.append('slide[name]', this.slide.name);
           let i = 0;
           for (var k in self.boxes ) {
                for (var l in self.boxes[k] ) {
                    if(l != 'errors') {
                        form_data.append('boxes['+i+']'+'['+l+']', self.boxes[k][l]);
                    }
                }
                i++;
           }
           console.log(self.boxes);
           
           axios
                .post(route,   form_data, { headers: {
                    'Content-Type': 'multipart/form-data',
                    'boundary' : Math.random().toString().substr(2)
                }})
                .then((response) => {
                    window.location = self.return;
                }).catch((error) => {
                    if (422 === error.response.status) {
                        
                        this.errors = error.response.data.errors;
                        self = this;
                        for (var k in this.errors ) {
                            var temp = k.split(".");

                            if(temp[0] == 'boxes') {
                                self.boxes[temp[1]]['errors'][temp[2]] = this.errors[k][0];
                            }
                        }
                    } else {
                        this.errorGlobal = error.response.data.message;
                    }
            }); 
             
        },
       
    },
    mounted() {
        Array.prototype.move = function(from, to) {
            this.splice(to, 0, this.splice(from, 1)[0]);
            return this;
        };
        $(document).ready(function() {
            $('.summerWojtekB').summernote();
         
        })
        
        this.contents.forEach(function(v) {
            v.progress = false;
            v.progress_percent = 0;
        })
        this.boxes = this.contents;
        console.log(this.boxes);
    },

    
 };
 </script>
 