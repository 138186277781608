<template>
    <div >
        <div class="block-title m-b">
            <div class="row">
                <div class="col-md-12">
                    <h1>Questionnaire</h1>
                </div>
            </div>
        </div>
        <div v-if="questionData" class="block-questionnaire m-b">
            <div v-if="showResult && !isAnswerCorrect">
                <div class="alert alert-danger">
                    Wrong answer!
                </div>
            </div>
            <div v-if="showResult && isAnswerCorrect">
                <div class="alert alert-success">
                    Correct answer!
                </div>
            </div>
        <div class="m-b">
            <h3>{{questionData[currentIndex].question}}</h3>
        </div>
        <h3>{{ questionData[currentIndex].title }}</h3>
        <div v-if="questionData[currentIndex].type === 'radio'">
          <!-- Radio button options -->
          <div v-for="(option, index) in questionData[currentIndex].answers" :key="index">
            <label 
            :class="{
              'answer-selected': showResult && isAnswerCorrect && selectedAnswer === option.answer,
              'wrong-answer': showResult && !isAnswerCorrect && selectedAnswer === option.answer && isAnswerSelected,
              'answer-not-selected': !showResult && selectedAnswer === option.answer && isAnswerSelected,
              'correct-answer': showResult && option.correct === 1
            }">
              <input 
                :disabled="showResult"
                type="radio" 
                :name="'question_' + questionData[currentIndex].id" 
                :value="option.id" 
                v-model="selectedAnswer"
              >
              {{ option.answer }}
            </label>
          </div>
        </div>
        <div v-else-if="questionData[currentIndex].type === 'check'">
          <!-- Checkbox options -->
          <div v-for="(option, index) in questionData[currentIndex].answers" :key="index">
            <label :class="{
              'answer-selected': showResult && isAnswerCorrect && selectedAnswers.includes(option.id),
              'wrong-answer': showResult && !isAnswerCorrect && selectedAnswers.includes(option.id) && isAnswerSelected,
              'answer-not-selected': !showResult && selectedAnswers.includes(option.id) && isAnswerSelected,
              'correct-answer': showResult && option.correct === 1
            }">
              <input 
                :disabled="showResult"
                type="checkbox" 
                :value="option.id" 
                v-model="selectedAnswers"
              >
              {{ option.answer }}
            </label>
          </div>
        </div>
        <br>
        <div class="row m-b">
            <div v-if="!showResult" class="col-md-12 m-b">
                <button  @click="checkAnswer" class="btn btn-small btn-primary">Answer <i class="fas fa-chevron-right" aria-hidden="true"></i></button>
            </div>
            <div v-if="showResult && showNextBtn" class="col-md-12 m-b">
                <button @click="getNextQuestion" class="btn btn-small btn-primary">Next <i class="fas fa-chevron-right" aria-hidden="true"></i></button>
            </div>
        </div>
      </div>
      <div v-else>
        <p>Brak więcej pytań</p>
        <!-- Możesz dodać coś tutaj dla zakończonego kwestionariusza -->
      </div>

    </div>
  </template>
  
  <script>
  export default {
    props: {
      questions: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        showNextBtn: true,
        questionData: null,
        selectedAnswer: null,
        selectedAnswers: [],
        showResult: false,
        isAnswerCorrect: false,
        showNext: false,
        currentIndex: 0, // Indeks bieżącego pytania
        isAnswerSelected: false // Flaga sprawdzająca, czy została wybrana odpowiedź
      };
    },
    methods: {
      async checkAnswer() {
       // alert('1');
        if (this.questionData[this.currentIndex].type === 'radio') {
          this.isAnswerCorrect = this.selectedAnswer === this.questionData[this.currentIndex].answers.find(answer => answer.correct === 1).id;
        } else if (this.questionData[this.currentIndex].type === 'check') {
          const correctAnswers = this.questionData[this.currentIndex].answers.filter(answer => answer.correct === 1);
          const selectedCorrect = correctAnswers.every(answer => this.selectedAnswers.includes(answer.id));
          const selectedIncorrect = this.selectedAnswers.some(answer => !correctAnswers.find(item => item.id === answer));
  
          this.isAnswerCorrect = selectedCorrect && !selectedIncorrect;
        }
  
        this.showResult = true;
        this.showNext = true;
        this.isAnswerSelected = true;
        if (this.currentIndex == this.questionData.length -1 ) {
            this.showNextBtn = false;
            $(".next-slide-btn").removeClass('disabled-link');
        }
      },
      async getNextQuestion() {
       // alert('2');
        if (this.currentIndex < this.questionData.length - 1) {

          this.currentIndex++;
          this.selectedAnswer = null;
          this.selectedAnswers = [];
          this.showResult = false;
          this.isAnswerCorrect = false;
          this.showNext = false;
          this.isAnswerSelected = false;
          this.prepareMusic();
          
        }
      },
      async prepareMusic() {
        let uris = [];
          
          if(this.questionData[this.currentIndex]['mp3']) {
            var mp3 = window.location.origin+'/'+this.questionData[this.currentIndex]['mp3'];
            console.log(mp3);
            uris.push(mp3);
          }
          this.questionData[this.currentIndex].answers.forEach(function(v) {
            if(v.mp3) {
              var mp3 = window.location.origin+'/'+v.mp3;
              uris.push(mp3);
            }
          })
          if(uris.length > 0) {

            console.log(uris);

            var  proms = uris.map(uri => fetch(uri).then(r => r.blob()));
              Promise.all(proms).then(blobs => {
                      let blob = new Blob(blobs),
                      blobUrl = URL.createObjectURL(blob),
                      audio = new Audio(blobUrl);
                      console.log(audio.src);
                      document.getElementById('testowo-rr').innerHTML = '<p> Loading...</p>';
                      document.getElementById('testowo-rr').innerHTML = '<audio  controlsList="nodownload" controls autoplay  style="position:relative; top:5px; left:5px; height:24px;"><source type="audio/mpeg" src="'+audio.src+'"></audio>';
            });
            
          } else {
            $('#rxr').hide();
          }
      } 
    },
    async mounted() {

      if (this.questions.length > 0) {
        this.questionData = this.questions;
      }
      this.prepareMusic();
      $(document).ready(function() {
        $(".next-slide-btn").addClass('disabled-link');
      })
      
    }
  };
  </script>
  
  <style>
  /* Twoje style dla komponentu */
  .answer-selected {
    color: #23c6c8;
  }
  .wrong-answer {
    color: #ED5565;
  }
  .answer-not-selected {
    color: black;
  }
  .correct-answer {
    color: #23c6c8;
  }
  </style>
  
  
  
  