<template>
    <form @submit.prevent="store(event)" method="post" class="">
    <div class="wrapper wrapper-content">
        <div class="container">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                    <div class="ibox">
                    <div class="ibox-content">
                        <div class="page-header">
                            <div class="row">
                                <div class="col-md-12">
                                    <h1 v-if="invite"> Invite Test</h1>
                                    <h1  v-if="admin">Add user</h1>
                                    <h1 v-else>Register</h1>
                                </div>
                            </div>
                        </div>
                        <div v-if="admin"  class="form-group">
                            <label>User role*</label>
                            <select v-model="form.level" class="form-control">
                                <option>Admin</option>
                                <option>User</option>
                            </select>
                        </div>
                        <div class="row section-login m-t">
                            <div class="col-md-12 m-b-sm">
                                <label><input type="radio"  v-model="form.type"  name="type" value="business" checked=""> Business</label>
                                <label><input type="radio"  v-model="form.type"  class="m-l-xs" value="individual" name="type"> Individual</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>First name(s)*</label>
                            <input v-model="form.first_name" type="text" class="form-control"
                             :class="{'is-invalid': errors.first_name }">
                            <small v-if="errors.first_name" class="text-danger">{{errors.first_name[0]}}</small>
                        </div>
                        <div class="form-group">
                            <label>Middle name(s)</label>
                            <input v-model="form.middle_name" type="text" class="form-control"
                             :class="{'is-invalid': errors.middle_name }">
                            <small v-if="errors.middle_name" class="text-danger">{{errors.middle_name[0]}}</small>
                        </div>
                        <div class="form-group">
                            <label>Last name*</label>
                            <input v-model="form.last_name" type="text" class="form-control"
                             :class="{'is-invalid': errors.last_name }">
                            <small v-if="errors.last_name" class="text-danger">{{errors.last_name[0]}}</small>
                        </div>
                        <div v-if="form.type == 'business'" class="form-group">
                            <label>Company name*</label>
                            <input v-model="form.company_name" type="text" class="form-control"
                             :class="{'is-invalid': errors.company_name }">
                            <small v-if="errors.company_name" class="text-danger">{{errors.company_name[0]}}</small>
                        </div>
                        <div v-if="form.type == 'business'" class="form-group">
                            <label>Position in company*</label>
                            <input v-model="form.position" type="text" class="form-control"
                             :class="{'is-invalid': errors.position }">
                            <small v-if="errors.position" class="text-danger">{{errors.position[0]}}</small>
                        </div>
                        <div class="form-group">
                            <label>Address 1*</label>
                            <input v-model="form.address_1" type="text" class="form-control"
                             :class="{'is-invalid': errors.address_1 }">
                            <small v-if="errors.address_1" class="text-danger">{{errors.address_1[0]}}</small>
                        </div>
                        <div class="form-group">
                            <label>Address 2</label>
                            <input v-model="form.address_2" type="text" class="form-control"
                             :class="{'is-invalid': errors.address_2 }">
                            <small v-if="errors.address_2" class="text-danger">{{errors.address_2[0]}}</small>
                        </div>
                        
                        
                        <div class="form-group">
                            <label>City/Town*</label>
                            <input v-model="form.city" type="text" class="form-control"
                             :class="{'is-invalid': errors.city }">
                            <small v-if="errors.city" class="text-danger">{{errors.city[0]}}</small>
                        </div>
                        <div class="form-group">
                            <label>County*</label>
                            <input v-model="form.county" type="text" class="form-control"
                             :class="{'is-invalid': errors.county }">
                            <small v-if="errors.county" class="text-danger">{{errors.county[0]}}</small>
                        </div> 
                        <div class="form-group">
                            <label>Post Code*</label>
                            <input v-model="form.code" type="text" class="form-control"
                             :class="{'is-invalid': errors.code }">
                            <small v-if="errors.code" class="text-danger">{{errors.code[0]}}</small>
                        </div>
                        <div class="form-group">
                            <label>Country*</label>
                            <select class="form-control" v-model="form.country"  >
                                <option v-for="country in countries" :value="country">{{country}}</option>
                            </select>
                            <small v-if="errors.country" class="text-danger">{{errors.country[0]}}</small>
                        </div>
                        <div class="form-group">
                            <label>Phone number</label>
                            <input v-model="form.phone_number" type="text" class="form-control"
                             :class="{'is-invalid': errors.phone_number }">
                            <small v-if="errors.phone_number" class="text-danger">{{errors.phone_number[0]}}</small>
                        </div>
                        
                        <div v-if="invite" class="form-group">
                            <label>Email*</label>
                            <input disabled v-model="invite.email" type="email" class="form-control"
                             :class="{'is-invalid': errors.email }">
                            <small v-if="errors.email" class="text-danger">{{errors.email[0]}}</small>
                        </div>
                        <div v-if="!invite" class="form-group">
                            <label>Email*</label>
                            <input v-model="form.email" type="email" class="form-control"
                             :class="{'is-invalid': errors.email }">
                            <small v-if="errors.email" class="text-danger">{{errors.email[0]}}</small>
                        </div>
                        <div v-if="!invite" class="form-group">
                            <label>Confirm email*</label>
                            <input v-model="form.email_confrim" type="email" class="form-control"
                             :class="{'is-invalid': errors.email_confrim }">
                            <small v-if="errors.email_confrim" class="text-danger">{{errors.email_confrim[0]}}</small>
                        </div>
                        <div class="form-group">
                            <label>Password*</label>
                            <input v-model="form.password" type="password" class="form-control"
                             :class="{'is-invalid': errors.password && errors.password[0] != 'Confirmation password is not correct.' }">
                            <small v-if="errors.password && errors.password[0] != 'Confirmation password is not correct.'" class="text-danger">{{errors.password[0]}}</small>
                        </div>
                        <p>Your password must:<br>
                        - be at least 8 characters long<br>
                        - contain at least one number<br>
                        - contain at least one upper case<br>
                        - contain one special character i.e. ! " £ $ % ^ &amp; * ( )
                        </p>
                        <div class="form-group">
                            <label>Confirm password*</label>
                            <input v-model="form.password_confirmation" type="password" class="form-control"
                             :class="{'is-invalid': errors.password && errors.password[0] == 'Confirmation password is not correct.' }">
                            <small v-if="errors.password && errors.password[0] == 'Confirmation password is not correct.'" class="text-danger">{{errors.password[0]}}</small>
                        </div>
                        <div class="row">
                        <a href="javascript:history.back()" style="height:33px; margin-left:15px; margin-right:5px;" class="btn btn-default">Back</a>
                        <div v-if="admin" class="form-group">
                            
                            <button  href="register-success.html" class="btn btn-primary">Save</button>
                        </div>
                        
                        <button v-else href="register-success.html" class="btn btn-primary block full-width m-b">Register</button>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</form>
</template>
<script>
export default {
    props: {
        'admin':0,
        'countries': [],
        'invite' : false
    },
    data() {
        return {
            errors: [],
            form: {
                type: 'business',
                first_name: '',
                level: 'User'
            }
        }
    },
    methods: {
        store(event) {
            self = this;
            var route = this.admin ? '/users/store' : '/register';
            if(self.invite) {
                this.form.email = this.invite.email;
                this.form.email_confrim = this.invite.email;
            }
            
            //wojtekgxost@wp.pl
            axios
                .post(route,  this.form, { headers: {
                }})
                .then((response) => {
                    if(self.invite) {
                        window.location = '../collectInvite/'+this.invite.hash_link;
                    } else if(self.admin) {
                        window.location = '../users';
                    } else {
                        window.location = 'registered';
                    }
                    
                }).catch((error) => {
                    if (422 === error.response.status) {
                        this.errors = error.response.data.errors;
                    } else {
                        this.errorGlobal = error.response.data.message;
                    }
            });  
        }
    }
}
</script>